/*
©2022 Pivotree | All rights reserved
*/
import { useTheme } from '@emotion/react';
import { AccessTime } from '@mui/icons-material';
import { Typography, Dialog as _Dialog } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeSession } from '../../utils/auth';

interface Props {}

const BootstrapDialog = styled(_Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const SessionPrompt: FC<Props> = (props: Props) => {
  const theme: any = useTheme();
  const navigate = useNavigate();
  const [remainingTime, setRemainingTime] = useState(5);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime((prevState) => prevState - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (remainingTime === 0) {
      removeSession();
      navigate('/login');
    }
  }, [remainingTime]);

  return (
    <BootstrapDialog
      onClose={() => {}}
      aria-labelledby='customized-dialog-title'
      open={true}
      scroll='body'
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '600px',
          transform: 'translate(0,-40%)',
        },
      }}
    >
      <DialogTitle
        sx={{
          p: '12px',
          bgcolor: theme.palette.primary.main,
          color: theme.palette.common.white,
          display: 'flex',
        }}
      >
        <AccessTime
          sx={{ color: theme.palette.common.white, mb: '-4px', mr: '6px' }}
          fontSize='medium'
        />
        <Typography sx={{ color: theme.palette.common.white }}>
          Session Expired
        </Typography>
      </DialogTitle>
      <DialogContent dividers sx={{ height: '100px' }}>
        <Typography sx={{ width: 'fit-content !important' }}>
          Session Expired, You will be automatically redirected to the login
          page in
          <span
            style={{ color: theme.palette.primary.main, fontWeight: 'bold' }}
          >
            {' '}
            {remainingTime}{' '}
          </span>
          seconds.
        </Typography>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default SessionPrompt;
