/* eslint-disable react/jsx-pascal-case */
import { useTheme } from '@emotion/react';
import { Logout, Menu as MenuIcon, Search } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Menu, Sidebar, MenuItem as _MenuItem } from 'react-pro-sidebar';
import { useNavigate } from 'react-router';
import Logo from '../../assets/icons/logo.svg';
import { removeSession } from '../../utils/auth';
import { getIconByName } from '../../utils/icon';
import MenuItem from '../menu/MenuItem';
import { menus as Menus } from './json';
import { Container, Image, Input, LogoBox, SearchBox } from './style';

interface Props {
  collapsed: boolean;
  selected: any;
  collapseSidebar: (collapsed?: boolean) => void;
  onMenuClick: (id: string) => void;
}

const SideBar = (props: Props) => {
  const { collapseSidebar, collapsed, selected } = props;
  const theme: any = useTheme();
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const [menus, setMenus]: any = useState(Menus);

  useEffect(() => {
    const menus = Menus.filter(
      (menu: any) =>
        menu.title.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    setMenus(menus);
  }, [value]);

  const handleCollapseSidebar = (value: boolean) => {
    setValue('');
    collapseSidebar(value);
  };

  const logout = () => {
    removeSession();
    navigate('/login');
  };

  return (
    <Container
      onMouseEnter={() => handleCollapseSidebar(false)}
      onMouseLeave={() => handleCollapseSidebar(true)}
      style={{ zIndex: 9999 }}
    >
      <Sidebar
        width='220px'
        collapsedWidth='50px'
        backgroundColor={theme.palette.primary.main}
        defaultCollapsed
      >
        <Menu>
          <_MenuItem
            key='menu-item-0'
            id='menu-item-0'
            className='menu-item-0'
            style={{
              paddingLeft: '10px',
              paddingRight: '10px',
            }}
          >
            {!collapsed ? (
              <>
                <SearchBox>
                  <Search
                    fontSize='small'
                    style={{
                      marginLeft: '4px',
                      color: theme.palette.common.white,
                      fill: theme.palette.common.white,
                    }}
                  />
                  <Input
                    id='menu-search'
                    onChange={(e: any) => setValue(e.target.value)}
                    value={value}
                    placeholder='Search'
                  />
                </SearchBox>
              </>
            ) : (
              <MenuIcon
                style={{
                  color: theme.palette.common.white,
                  fill: theme.palette.common.white,
                }}
              />
            )}
          </_MenuItem>
          <Scrollbars
            autoHide={true}
            hideTracksWhenNotNeeded={true}
            autoHeight={true}
            autoHeightMax={'calc(100vh - 110px)'}
          >
            {menus
              .sort((a: any, b: any) => a.id.localeCompare(b.id))
              .map((menu: any, idx: number) => {
                return (
                  <MenuItem
                    icon={getIconByName(menu.icon)}
                    title={menu.title}
                    key={'menu-item-' + (idx + 1)}
                    onClick={() => props.onMenuClick(menu.id)}
                    isSelected={selected && menu.id === selected.id}
                  />
                );
              })}
          </Scrollbars>
          <_MenuItem
            key='menu-item-z'
            id='menu-item-z'
            style={{
              //   paddingLeft: collapsed ? '14px' : '20px',
              // position: 'fixed',
              bottom: 0,
              left: '30px',
              alignItems: 'center',
            }}
          >
            <LogoBox>
              {!collapsed && (
                <>
                  <Image src={Logo} alt='logo' />

                  <IconButton onClick={logout}>
                    <Tooltip title='Logout'>
                      <Logout
                        sx={{
                          color: theme.palette.common.white,
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </>
              )}
              {collapsed && (
                <IconButton>
                  <Logout
                    sx={{
                      color: theme.palette.common.white,
                    }}
                  />
                </IconButton>
              )}
              {/* <div style={{ fontSize: '10px' }}>VER. 1.0</div> */}
            </LogoBox>
          </_MenuItem>
        </Menu>
      </Sidebar>
    </Container>
  );
};
export default SideBar;
