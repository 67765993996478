import styled from '@emotion/styled';

export const SearchBox = styled.div({
  display: 'flex',
  border: 'solid 1px #fff',
  borderRadius: '4px',
  height: '32px',
  alignItems: 'center',
  ':hover': {
    border: '1px solid #fff',
  },
});

export const LogoBox = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const Input = styled.input({
  border: 'none',
  background: 'transparent',
  color: '#fff',
  ':focus-visible': {
    outline: 'none',
  },
});

export const Image = styled.img({
  height: '40px',
  width: '90px',
});

export const Container = styled.div({
  display: 'flex',
  height: '100%',
  position: 'fixed',
  left: 0,
  top: 0,
  backgroundColor: '#d8523d',
});
