import { FC, useEffect, useState } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { useDispatch, useSelector } from 'react-redux';
import SessionPrompt from '../../components/dialog/SessionPrompt';
import SideBar from '../../components/sidebar/SideBar';
import { menus } from '../../components/sidebar/json';
import { toggleSessionPrompt } from '../../redux/root_actions';
import { isUserAuthenticated } from '../../utils/auth';
import { ContentDiv } from './style';

interface Props {}
const Documentation: FC<Props> = (props: Props) => {
  const [openDialoag, toggleDialog]: any = useState(true);
  const { collapseSidebar, collapsed } = useProSidebar();
  // const [alignment, setAlignment] = useState('api_docs');
  const [selectedMenu, selectMenu]: any = useState(null);
  const [htmlContent, setHtmlContent]: any = useState('');

  // useSelector
  const sessionPrompt = useSelector((state: any) => state.Common.sessionPrompt);

  //dispatch
  const dispatch = useDispatch();

  const createLink = (menu: any) => {
    if (menu) {
      const node = document.getElementById('toctitle');
      if (node) {
        node.style.fontWeight = 'bold';
      }
      let divContainer: any = null;
      let useCaseLink: any = null;
      let testCaseLink: any = null;

      const div = document.getElementById('link_container');
      const link1 = document.getElementById('use_case_link');
      const link2 = document.getElementById('test_case_link');

      if (!div) {
        divContainer = document.createElement('div');
        divContainer.id = 'link_container';
      }

      if (!link1) {
        useCaseLink = document.createElement('a');
        const useCaseNode = document.createTextNode('Use Cases');
        useCaseLink.appendChild(useCaseNode);
        useCaseLink.title = 'Use Cases';
        useCaseLink.id = 'use_case_link';
        useCaseLink.style.color = '#001f5b';
        if (menu.useCase) {
          useCaseLink.href = menu.useCase;
          useCaseLink.target = '_blank';
          useCaseLink.style.display = 'initial';
          useCaseLink.style.fontWeight = 'bold';
        } else {
          useCaseLink.style.display = 'none';
        }
        useCaseLink.style.fontSize = '16px';
        useCaseLink.style.marginRight = '16px';
      } else {
        useCaseLink.href = menu.useCase;
      }

      if (!link2) {
        testCaseLink = document.createElement('a');
        const testCaseNode = document.createTextNode('Test Cases');
        testCaseLink.appendChild(testCaseNode);
        testCaseLink.title = 'Test Cases';
        testCaseLink.id = 'test_case_link';
        testCaseLink.style.color = '#001f5b';
        if (menu.testCase) {
          testCaseLink.href = menu.testCase;
          testCaseLink.target = '_blank';
          testCaseLink.style.display = 'initial';
          testCaseLink.style.fontWeight = 'bold';
        } else {
          testCaseLink.style.display = 'none';
        }
        testCaseLink.style.fontSize = '16px';
      } else {
        testCaseLink.href = menu.testCase;
      }

      // append elements.
      // if (node) {
      //   if (!link1) {
      //     divContainer.appendChild(useCaseLink);
      //   }
      //   if (!link2) {
      //     divContainer.appendChild(testCaseLink);
      //   }
      //   if (!div) {
      //     node.appendChild(divContainer);
      //   }
      // }
    }
  };

  const createFloatingText = () => {
    const node = document.getElementById('footer');
    const footerText = document.getElementById('footer-text');
    if (footerText) {
      footerText.style.color = '#fff';
    }

    if (node) {
      node.style.backgroundColor = '#001f5b';
      node.style.paddingLeft = '50px';
    }

    let textElem: any = null;

    const warning_text = document.getElementById('warning_text');

    if (!warning_text) {
      textElem = document.createElement('div');
      textElem.id = 'warning_text';
      textElem.innerHTML =
        'Documentation is for internal use only and subject to change.<br>©2023 Pivotree | All rights reserved.';
      textElem.className = 'warning-text';
      textElem.style.color = '#fff';
    }

    // append elements.
    if (node) {
      if (!warning_text) {
        node.prepend(textElem);
      }
    }
  };

  useEffect(() => {
    createLink(selectedMenu);
    createFloatingText();
  }, [selectedMenu]);

  useEffect(() => {
    const menu: any = menus[0];
    const htmlContent = require(`../../assets/docs/api_docs/${menu.html}.html`);
    setHtmlContent(htmlContent);
    selectMenu(menu);
  }, []);

  const handleMenuClick = (id: string) => {
    //check if session has expired or not
    if (isUserAuthenticated()) {
      const menu: any = menus.find((menu: any) => menu.id === id);
      const htmlContent = require(`../../assets/docs/api_docs/${menu.html}.html`);
      setHtmlContent(htmlContent);
      selectMenu(menu);
    } else {
      dispatch(toggleSessionPrompt(true));
    }
  };

  useEffect(() => {
    const tables: any = document.getElementsByTagName('table');
    for (let item of tables) {
      item.parentElement.style.overflow = 'auto';
    }
  });

  return (
    <>
      {/* <AlertDialog open={openDialoag} setOpen={toggleDialog} /> */}
      {/* <div style={{ visibility: openDialoag ? 'hidden' : 'visible' }}> */}
      <div>
        <SideBar
          collapseSidebar={collapseSidebar}
          collapsed={collapsed}
          onMenuClick={(path: string) => handleMenuClick(path)}
          selected={selectedMenu}
        />

        <ContentDiv>
          <img
            src={require('../../assets/images/logo.jpg')}
            height='80px'
            width={'210px'}
            alt='Logo'
            style={{
              marginLeft: '65px',
            }}
          />
          <div dangerouslySetInnerHTML={{ __html: htmlContent.default }} />
          {/* <Toggle alignment={alignment} setAlignment={setAlignment} /> */}
        </ContentDiv>
      </div>
      {sessionPrompt && <SessionPrompt />}
    </>
  );
};

export default Documentation;
