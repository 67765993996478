import {
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESS,
  RESET_STORE,
  TOGGLE_SESSION_PROMPT,
} from '../../constants/actionTypes';
type CommonAction = { type: string; payload: {} | string };

export const loginUser = (data: any): CommonAction => ({
  type: LOGIN_USER,
  payload: data,
});
export const loginUserSuccess = (data: any): CommonAction => ({
  type: LOGIN_USER_SUCCESS,
  payload: data,
});
export const loginUserFailed = (data: any): CommonAction => ({
  type: LOGIN_USER_FAILED,
  payload: data,
});
export const toggleSessionPrompt = (data: boolean): CommonAction => ({
  type: TOGGLE_SESSION_PROMPT,
  payload: data,
});
export const resetStore = (data: any): CommonAction => ({
  type: RESET_STORE,
  payload: data,
});
