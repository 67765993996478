import Documentation from './pages/documentation/Documentation';
import Login from './pages/login/Login';

export const routes = [
  {
    path: '/login',
    id: 'login',
    component: Login,
  },
  {
    path: '/documentation',
    id: 'documentation',
    component: Documentation,
  },
  {
    path: '/',
    id: 'documentation',
    component: Documentation,
  },
];
