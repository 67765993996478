import {
  Copyright,
  Lock,
  Login as LoginIcon,
  Person,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import {
  Alert,
  Card,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  useTheme,
} from '@mui/material';
import CryptoJS from 'crypto-js';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser, toggleSessionPrompt } from '../../redux/root_actions';
import {
  FooterLabel,
  LoginButton,
  LoginDiv,
  LoginLabel,
  LoginLayoutWrapper,
  StyledForm,
} from './style';
interface Props {
  children?: null;
}

const Login: FC<Props> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [showPassword, setShowPassword] = React.useState(false);
  const [pcapsState, passscapsState] = useState(false);
  const [invalidCreds, setInvalidCreds] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [state, setState] = useState({
    username: '',
    password: '',
  });

  useEffect(() => {
    dispatch(toggleSessionPrompt(false));
  }, []);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handlekeypress = (e: KeyboardEvent) => {
    if (e.getModifierState('CapsLock')) {
      passscapsState(true);
    } else {
      passscapsState(false);
    }
  };

  const handleChange = (event: any): void => {
    let name = event?.target.name;
    let value = event?.target.value;
    event.target.setCustomValidity('');
    setInvalidCreds(false);
    setState({
      ...state,
      [name]: value,
    });
  };

  const onFormSubmit = (event: any): void => {
    event.preventDefault();

    var encryptedData = CryptoJS.AES.encrypt(
      state.password,
      process.env.REACT_APP_AES_KEY || ''
    ).toString();

    const url = process.env.REACT_APP_REST_URL;
    dispatch(
      loginUser({
        data: {
          url: `${url}/auth/login`,
          options: {
            method: 'POST',
            body: {
              username: state.username,
              password: encryptedData,
            },
          },
        },
        callback: (resp: any) => {
          if (resp) {
            navigate('/documentation');
          } else {
            setInvalidCreds(true);
          }
        },
        errorCallback: () => {
          setInvalidCreds(true);
        },
      })
    );
  };

  return (
    <Container fixed style={{ padding: '0' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={6}>
          <Card sx={{ maxWidth: 345 }}>
            <LoginLayoutWrapper>
              <Container
                fixed
                style={{ textAlign: 'center', marginBottom: '16px' }}
              >
                <img
                  src={require('../../assets/images/logo.jpg')}
                  height='80px'
                  width={'210px'}
                  alt='Logo'
                />
              </Container>
              <Container fixed>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0px 8px',
                    borderTop: 'solid 1px #a7a7a7',
                  }}
                >
                  <LoginLabel>
                    Login To Pivotree WMS API Documentation
                  </LoginLabel>
                </div>
                {invalidCreds && (
                  <Alert style={{ marginTop: '8px' }} severity='error'>
                    {'Invalid Credentials'}
                  </Alert>
                )}

                <LoginDiv>
                  {pcapsState && (
                    <Typography
                      fontSize={'14px'}
                      fontWeight={500}
                      color='#1565c0'
                      style={{ margin: '0px 10px' }}
                    >
                      {'Caps Lock is on'}
                    </Typography>
                  )}
                  <StyledForm onSubmit={onFormSubmit}>
                    <FormControl fullWidth sx={{ m: 1 }} variant='outlined'>
                      <InputLabel htmlFor='outlined-adornment-username'>
                        User Name
                      </InputLabel>
                      <OutlinedInput
                        id='outlined-adornment-username'
                        type={'text'}
                        name='username'
                        value={state.username}
                        required={state.username ? false : true}
                        onKeyPress={(event: any) => handlekeypress(event)}
                        onInvalid={(event: any) => {
                          if (state.username) {
                            //do nothing
                          } else {
                            event.target.setCustomValidity(
                              'Username is required'
                            );
                          }
                        }}
                        onChange={(event: any) => handleChange(event)}
                        startAdornment={
                          <InputAdornment position='start'>
                            <Person color='primary' />
                          </InputAdornment>
                        }
                        placeholder='Enter user name'
                        label='User Name'
                        autoFocus
                      />
                    </FormControl>

                    <FormControl fullWidth sx={{ m: 1 }} variant='outlined'>
                      <InputLabel
                        color='primary'
                        htmlFor='outlined-adornment-password'
                      >
                        {'Password'}
                      </InputLabel>
                      <OutlinedInput
                        id='outlined-adornment-password'
                        type={showPassword ? 'text' : 'password'}
                        value={state.password}
                        name='password'
                        // ref={register({ required: true, maxLength: 80 })}
                        required={state.password ? false : true}
                        onKeyPress={(event: any) => handlekeypress(event)}
                        onChange={(event: any) => handleChange(event)}
                        onInvalid={(event: any) => {
                          if (state.password) {
                            //do nothing
                          } else {
                            event.target.setCustomValidity(
                              'Password is required'
                            );
                          }
                        }}
                        placeholder='Enter password'
                        startAdornment={
                          <InputAdornment position='start'>
                            <Lock color='primary' />
                          </InputAdornment>
                        }
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge='end'
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label='Password'
                      />
                    </FormControl>

                    <FormControl fullWidth sx={{ m: 1 }} variant='outlined'>
                      <LoginButton
                        onClick={() => {}}
                        variant='contained'
                        type='submit'
                        startIcon={<LoginIcon />}
                      >
                        {'Login'}
                      </LoginButton>
                    </FormControl>
                  </StyledForm>
                </LoginDiv>
              </Container>
            </LoginLayoutWrapper>
          </Card>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: '24px',
              position: 'fixed',
              bottom: '20px',
            }}
          >
            <FooterLabel>
              {<Copyright color='primary' fontSize={'small'} />}{' '}
            </FooterLabel>
            <FooterLabel>{'2023 Pivotree | All rights reserved'} </FooterLabel>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Container
            fixed
            style={{
              padding: '30px',
              height: 'auto',
              width: 'auto',
              background: theme.palette.primary.main,
              marginTop: '24px',
            }}
          >
            <img
              src={require('../../assets/images/PATBanner.png')}
              height='100%'
              width='100%'
            />
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
