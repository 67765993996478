import {
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCESS,
  TOGGLE_SESSION_PROMPT,
} from '../../constants/actionTypes';

type CommonAction = { type: string; payload: any | string };
type State = {
  loading: boolean;
  sessionPrompt: boolean;
  authData: any;
};

const INIT_STATE = {
  authData: {},
  loading: false,
  sessionPrompt: false,
};

const Common = (state: State = INIT_STATE, action: CommonAction) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, authData: {} };
    case LOGIN_USER_SUCCESS:
      return { ...state, authData: action.payload, loading: false };
    case LOGIN_USER_FAILED:
      return { ...state, loading: false };
    case TOGGLE_SESSION_PROMPT:
      return { ...state, sessionPrompt: action.payload };
    default:
      return { ...state };
  }
};
export default Common;
