export const menus = [
  {
    id: 'catalog',
    title: 'Catalog',
    icon: 'Summarize',
    html: 'item_api_doc',
    useCase:
      'https://docs.google.com/spreadsheets/d/1o7_3NDbExTOYnYgKa8jyttTQY6V4drWX/',
    testCase:
      'https://docs.google.com/spreadsheets/d/114iMlZdCvULFUvaPaK9T0QfmcSxSqbEO/',
  },
  {
    id: 'receive',
    title: 'Receive',
    icon: 'CallReceived',
    html: 'receive_api_doc',
    useCase:
      'https://docs.google.com/spreadsheets/d/1I8bSdnT9C7T9MqqtkQVgIuAlWIXMr5MU/',
    testCase:
      'https://docs.google.com/spreadsheets/d/1AJ014s12G_ifpmF0rbcAZXHCZxZZI6IZ/',
  },
  {
    id: 'task',
    title: 'Task',
    icon: 'Assignment',
    html: 'TaskMS',
    useCase:
      'https://docs.google.com/spreadsheets/d/1vsk8HvZV8ymAc87SJARv5tZfWDeNjinF/',
    testCase:
      'https://docs.google.com/spreadsheets/d/1ANoM3ru6Qwb3iyVYH3WFFe5SY1Q4tjPQ2D2-qAPCBPg/',
  },
  {
    id: 'pack',
    title: 'Pack',
    icon: 'Inventory',
    html: 'PackMS',
    useCase:
      'https://docs.google.com/spreadsheets/d/1tikcevmDyLYQAy_5nQU8ow7xjxsI4_Z9/',
    testCase:
      'https://docs.google.com/spreadsheets/d/1ygp_LJeZb7n_pttgM0jAtX_qqVKfwWI2/',
  },
  {
    id: 'ship',
    title: 'Ship',
    icon: 'LocalShipping',
    html: 'ShipMS-API-Docs',
    useCase:
      'https://drive.google.com/file/d/1HWG-AjD1B6AAH1hwihNbW3AKisyv6C-6/view',
    testCase:
      'https://docs.google.com/spreadsheets/d/1RcC0XDKQKuIDf04TMhGsRKa-qg7D3P24/',
  },
  {
    id: 'shipment',
    title: 'Shipment',
    icon: 'LocalShipping',
    html: 'ShipmentMS-API-Doc',
    useCase:
      'https://docs.google.com/spreadsheets/d/1R66hmY0anzONyIdmfSW-IhyaMqg1LsoB/view',
    testCase: '',
  },
  // {
  //   id: 'utility',
  //   title: 'Utility',
  //   icon: 'Code',
  //   html: 'utility_api_doc',
  //   useCase: '',
  //   testCase: '',
  // },
  // {
  //   id: 'search',
  //   title: 'Search',
  //   icon: 'ManageSearch',
  //   html: 'BarcodeSearchMS_API_doc',
  //   useCase: '',
  //   testCase: '',
  // },
  // {
  //   id: 'audit',
  //   title: 'Audit',
  //   icon: 'ContentPasteSearch',
  //   html: 'auditMSDoc',
  //   useCase: '',
  //   testCase: '',
  // },
  {
    id: 'layout',
    title: 'Layout',
    icon: 'AutoAwesomeMosaic',
    html: 'layout_api_doc',
    useCase: '',
    testCase: '',
  },
  // {
  //   id: 'moverequest',
  //   title: 'Move Request',
  //   icon: 'MoveUp',
  //   html: 'movereq_api_doc',
  //   useCase: '',
  //   testCase: '',
  // },
  {
    id: 'organization',
    title: 'Organization',
    icon: 'Apartment',
    html: 'organization_api_doc',
    useCase: '',
    testCase: '',
  },
  // {
  //   id: 'waves',
  //   title: 'Waves',
  //   icon: 'Waves',
  //   html: 'waves_api_doc',
  //   useCase: '',
  //   testCase: '',
  // },
  {
    id: 'inboundcontainer',
    title: 'Inbound Container',
    icon: 'MoveToInbox',
    html: 'inboundcontainer_api_doc',
    useCase: '',
    testCase: '',
  },
  // {
  //   id: 'userms',
  //   title: 'User Management',
  //   icon: 'Person',
  //   html: 'UserMS',
  //   useCase:
  //     'https://docs.google.com/spreadsheets/d/1NXxzm8Zp1vhZvDCtxXFi9Vd721g-iuD3',
  //   testCase:
  //     'https://docs.google.com/spreadsheets/d/1u2bxuZSgillxV7MoLVNvfNChwoGU3kIPgLWFs5cURdg',
  // },
  {
    id: 'inventoryms',
    title: 'Inventory',
    icon: 'Inventory',
    html: 'Inventory_api_doc',
    useCase:
      'https://docs.google.com/spreadsheets/d/14Vc6I0hBSztFHKyMSc9hIhNhIQSzVlh1',
    testCase:
      'https://docs.google.com/spreadsheets/d/14Vc6I0hBSztFHKyMSc9hIhNhIQSzVlh1',
  },
];
