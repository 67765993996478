import styled from '@emotion/styled';

export const ContentDiv = styled.div({
  marginLeft: '1rem',
  top: '40px',

  //   position: 'fixed',
  //   overflow: 'scroll',
  //   height: '100vh',
});
