import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LOGIN_USER } from '../../constants/actionTypes';
import { callApi } from '../../utils/callApi';
import {
  loginUserFailed,
  loginUserSuccess,
  toggleSessionPrompt,
} from './actions';

function* login({ payload }: any) {
  const { data, callback, errorCallback } = payload;
  try {
    const resp: Promise<any> = yield call(callApi, data);
    //@ts-ignore
    if (resp) {
      yield put(loginUserSuccess(resp));
      //@ts-ignore
      callback && callback(resp);
    } else {
      yield put(loginUserFailed({}));
      errorCallback && errorCallback(resp);
    }
  } catch (error: any) {
    if (error.message === 'Authorization failed') {
      yield put(toggleSessionPrompt(true));
    } else {
      yield put(loginUserFailed({}));
      errorCallback && errorCallback();
    }
  }
}

function* watchLogin() {
  yield takeEvery(LOGIN_USER, login);
}

function* commonSaga(): any {
  yield all([fork(watchLogin)]);
}

export default commonSaga;
