import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FC, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { routes } from './routes';
const theme = createTheme({
  palette: {
    primary: { main: '#001f5b' },
    secondary: { main: '#f3f3f3' },
    common: { black: '#000', white: '#fff' },
  },
});

interface Props {}
const App: FC<Props> = (props: Props) => {
  const loading = () => <div></div>;
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {routes.map(({ path, name, exact, ...route }: any, key: any) => {
          return (
            <Route
              path={path}
              element={
                path === '/login' ? (
                  <Suspense fallback={loading()}>
                    <route.component {...props} name={name} />
                  </Suspense>
                ) : (
                  <PrivateRoute>
                    <Suspense fallback={loading()}>
                      <route.component {...props} name={name} />
                    </Suspense>
                  </PrivateRoute>
                )
              }
            />
          );
        })}
      </Routes>
    </ThemeProvider>
  );
};

export default App;
