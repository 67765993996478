import { useTheme } from '@emotion/react';
import { MenuItem as Item } from 'react-pro-sidebar';
interface Props {
  icon: any;
  title: string;
  isSelected: boolean;
  onClick: any;
}
const MenuItem = (props: Props) => {
  const theme: any = useTheme();
  return (
    <Item
      icon={
        <props.icon
          fontSize='small'
          color={
            props.isSelected
              ? theme.palette.secondary.main
              : theme.palette.common.white
          }
          fill={
            props.isSelected
              ? theme.palette.secondary.main
              : theme.palette.common.white
          }
        />
      }
      style={{
        paddingRight: '16px',
        paddingLeft: '8px',
        backgroundColor: props.isSelected ? theme.palette.secondary.main : '',
        color: props.isSelected
          ? theme.palette.primary.main
          : theme.palette.secondary.main,
      }}
      onClick={props.onClick}
    >
      {props.title}
    </Item>
  );
};
export default MenuItem;
